import { array, bool, string, object, any } from 'prop-types';
import { useEffect, useRef } from 'react';
import Slider from 'react-slick';

import { getMarket } from 'utils/helpers';
import { ProductCard } from 'components/product/product-card';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './style.module.css';

function ProductsSlider({
  products,
  locale,
  fixedGrid,
  availableSizes,
  withLabel,
  currentList,
  camperlab,
  slidesToShow,
  forcedSettings,
  withPlaceholder,
  loading,
  withHover,
  squareImg,
  addToBagButton,
  goToStartOnChange = false,
}) {
  const market = getMarket(locale);
  const sliderRef = useRef(null);
  const productsLength = useRef(products?.length ?? 0);

  let settings = null;

  if (forcedSettings) {
    settings = forcedSettings;
  } else {
    settings =
      fixedGrid ?
        {
          dots: false,
          infinite: false,
          speed: 500,
          slidesToShow: !Number.isNaN(Number(slidesToShow)) ? Number(slidesToShow) : 4,
          initialSlide: 0,
          swipeToSlide: true,
          easing: false,
          draggable: true,
          swipe: true,
          useTransform: false,
          touchThreshold: 50,
          arrows: true,
          responsive: [
            {
              breakpoint: 1440,
              settings: {
                slidesToShow: products.length >= 3 ? 3 : products.length,
                arrows: true,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: products.length >= 2 ? 2 : products.length,
                arrows: true,
                slidesToScroll: 1,
              },
            },
          ],
        }
      : {
          dots: false,
          infinite: false,
          speed: 500,
          slidesToShow: 4.33,
          initialSlide: 0,
          swipeToSlide: true,
          easing: false,
          draggable: true,
          swipe: true,
          useTransform: false,
          touchThreshold: 50,
          responsive: [
            {
              breakpoint: 1440,
              settings: {
                slidesToShow: 3.33,
                arrows: false,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1.33,
                arrows: false,
                slidesToScroll: 1,
              },
            },
          ],
        };
  }

  useEffect(() => {
    if (goToStartOnChange && typeof products?.length === 'number' && products.length !== productsLength.current) {
      sliderRef.current.slickGoTo(0);
      productsLength.current = products.length;
    }
  }, [products.length, goToStartOnChange]);

  return (
    <Slider
      ref={(slider) => {
        sliderRef.current = slider;
      }}
      {...settings}
      className={styles.productsSlider}
    >
      {products && products.length > 0 ?
        products.map(({ id, name, targetId, prices, images, sizes, family, colors, url, sustainabilities, membersOnly }, index) => {
          const hasHoverImages =
            Object.values(images)
              .map((image) => image?.hover)
              .filter(Boolean).length > 0;
          const isVideo = Array.isArray(images.sources) && images.sources.some((source) => source.isVideo);
          return (
            <ProductCard
              key={id}
              id={id}
              name={name}
              target={targetId}
              prices={prices}
              images={
                isVideo ? images : (
                  {
                    xs: images.xs.main,
                    md: images.md.main,
                    lg: images.lg.main,
                    full: images.full.main,
                  }
                )
              }
              imagesHover={
                hasHoverImages ?
                  {
                    xs: images.xs?.hover,
                    md: images.md?.hover,
                    lg: images.lg?.hover,
                    full: images.full?.hover,
                  }
                : undefined
              }
              sizes={sizes}
              family={family}
              colors={colors}
              url={url}
              sustainabilities={sustainabilities}
              market={market}
              locale={locale}
              availableSizes={availableSizes}
              colXS={{ span: 24 }}
              colMD={{ span: 24 }}
              colLG={{ span: 24 }}
              colXL={{ span: 24 }}
              colXXL={{ span: 24 }}
              withHover={withHover}
              withLabel={withLabel}
              currentList={currentList}
              item={products[index]}
              index={index}
              camperlab={camperlab}
              membersOnly={membersOnly || false}
              withPlaceholder={withPlaceholder}
              voucherApplied={prices?.discountFromVoucher || false}
              loadingProp={loading}
              squareImg={squareImg}
              addToBagButton={addToBagButton}
            />
          );
        })
      : null}
    </Slider>
  );
}

ProductsSlider.propTypes = {
  products: array,
  locale: string,
  withLabel: bool,
  availableSizes: array,
  currentList: string,
  camperlab: bool,
  fixedGrid: bool,
  slidesToShow: string,
  forcedSettings: object,
  withPlaceholder: bool,
  loading: any,
  withHover: bool,
  squareImg: bool,
  addToBagButton: bool,
  goToStartOnChange: bool,
};

ProductsSlider.defaultProps = {
  products: [],
  withLabel: false,
  availableSizes: [],
  currentList: '',
  camperlab: false,
  fixedGrid: true,
  slidesToShow: undefined,
  loading: 'lazy',
  withHover: false,
  squareImg: false,
  addToBagButton: false,
};

export default ProductsSlider;
